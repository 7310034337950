window.grecaptcha = undefined

export default ({ store, $config }) => {
  const isMTYInstance =
    $config.FB_PROJECT_ID === 'os-sandbox-mty' ||
    $config.FB_PROJECT_ID === 'os-monterrey'
  if (isMTYInstance) {
    try {
      setTimeout(() => {
        const recaptchaScript = document.createElement('script')
        if ($config.GRECAPTCHA_TOKEN) {
          recaptchaScript.setAttribute(
            'src',
            `https://www.google.com/recaptcha/enterprise.js?render=${$config.GRECAPTCHA_TOKEN}`
          )
          recaptchaScript.setAttribute('async', 'true')
          recaptchaScript.setAttribute('defer', 'true')
          document.head.appendChild(recaptchaScript)
          recaptchaScript.onload = () => {
            window.grecaptcha?.enterprise?.ready(async () => {
              if (window?.grecaptcha || window?.grecaptcha?.render) {
                await store.commit('setReCaptcha', window.grecaptcha)
              } else {
                throw new Error('Error de no encontrar el grecaptcha')
              }
            })
          }
        } else {
          throw new Error('Error de no encontrar el grecaptcha')
        }
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
