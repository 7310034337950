
import cloneDeep from 'lodash/cloneDeep'
import {
  getClaveUsoPorExpediente,
  getClaveUsoPorLatLng,
  getGirosPorUso,
  getDensidadHabitacional,
  getDensidadComercialesServicios,
  getDensidadCrecimientoControlado,
  getDensidadCorredoresUrbanos,
  getDensidadCorredoresUrbanosArea,
  getDensidadSubcentrosUrbanos,
  getZonaConservacion,
} from '@/services/Ide'

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-ide',
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      rules: [(v) => !!v || this.$t('fieldRequired')],
      ruleRadio: [(v) => v !== null || this.$t('fieldRequired')],
      switchCodeOrMap: false,
      expediente: '',
      claveUso: null,
      usoSuelos: [],
      selectedUso: null,
      giros: [],
      selectedGiro: [],
      densidades: null,
      densidad: null,
      pendientes: null,
      pendiente: null,
      conservacion: null,
      updateMap: null,
      resetMap: null,
      location: {
        cp: null,
        state: null,
        city: null,
        neighborhood: null,
        street: null,
        outdoorNumber: null,
        interiorNumber: null,
        reference: null,
        lat: null,
        lng: null,
      },
      noData: false,
      landArea: null,
      dialogEditor: false,
      configMap: {
        basemap: 'roadmap',
        selectedMap: {},
      },
      loadingMounted: false,
      polygon: null,
      submitIDE: false,
      resetGiros: false,
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }, // listen to @input="handler"
    },
    computedUsos() {
      return this.giros.map((g) => {
        g.uso_suelo = g.cve_uso_suelo + ' ' + g.uso_suelo
        return g
      })
    },
    computedGiros() {
      if (this.giros && this.giros.length > 0) {
        return this.giros.map((g) => {
          g.giro = g.claveGrupo + ' ' + g.giro
          return g
        })
      } else {
        return []
      }
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.switchCodeOrMap = val?.switchCodeOrMap || false
        this.expediente = val?.expediente || null
        this.selectedUso = val?.selectedUso || null
        this.selectedGiro = val?.selectedGiro || []
        // this.densidad = val?.densidad || null
        this.pendiente = val?.pendiente || null
        this.showLandArea = val?.showLandArea || false
        this.landArea = val?.landArea || null
        this.conservacion = val?.conservacion || null
        this.updateMap = val?.updateMap || null
        this.resetMap = val?.resetMap || null
        this.location = val?.location || {
          cp: null,
          state: null,
          city: null,
          neighborhood: null,
          street: null,
          outdoorNumber: null,
          interiorNumber: null,
          reference: null,
          lat: null,
          lng: null,
        }
        if (
          this.obj.schema.disabled &&
          (this.expediente || (this.location.lat && this.location.lng))
        ) {
          // Not editable view, trigger calls
          if (this.switchCodeOrMap) {
            // By Map
            this.handlerClavePorLatLng()
          } else {
            // By Expediente
            this.handlerClavePorExpediente()
          }
          if (this.selectedUso) {
            this.setGirosCentro(this.selectedUso.cve_uso_suelo)
          }
        }
      }
    },
  },
  async mounted() {
    const self = this
    this.loadingMounted = false
    this.configMap.selectedMap = {
      title: this.obj.schema.selectedMap
        ? cloneDeep(this.obj.schema.selectedMap.title)
        : '',
      data: this.obj.schema.selectedMap
        ? JSON.parse(this.obj.schema.selectedMap.data)
        : {},
    }
    const layers = this.configMap.selectedMap?.data?.maplayers
    if (layers && layers.length > 0) {
      layers?.forEach(function (layer, index) {
        if (!layer.show) {
          self.configMap.selectedMap.data.maplayers[index].show = true
          self.configMap.selectedMap.data.maplayers[index].opacity = 25
        }

        const nameLayer = layer?.dataset?.alternate || layer.current_style

        const style = layer?.dataset?.default_style.name || ''

        // URL de la capa WMS EPSG:4326
        self.configMap.selectedMap.data.maplayers[index].simbologia =
          'https://mide.monterrey.gob.mx/geoserver/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&WIDTH=20&HEIGHT=20&' +
          'LAYER=' +
          nameLayer +
          '&STYLE=' +
          style +
          '&version=1.3.0&sld_version=1.1.0&legend_options=fontAntiAliasing%3Atrue%3BfontSize%3A12%3BforceLabels%3Aon'
      })
    }

    this.switchCodeOrMap = this.value?.switchCodeOrMap || false
    this.expediente = this.value?.expediente || null
    this.selectedUso = this.value?.selectedUso || null
    this.selectedGiro = this.value?.selectedGiro || []
    // this.densidad = this.value?.densidad || null
    this.pendiente = this.value?.pendiente || null
    this.showLandArea = this.value?.showLandArea || false
    this.landArea = this.value?.landArea || null
    this.conservacion = this.value?.conservacion || null
    this.updateMap = this.value?.updateMap || null
    this.resetMap = this.value?.resetMap || null
    this.location = this.value?.location || {
      cp: null,
      state: null,
      city: null,
      neighborhood: null,
      street: null,
      outdoorNumber: null,
      interiorNumber: null,
      reference: null,
      lat: null,
      lng: null,
    }

    if (
      this.obj.schema.disabled &&
      (this.expediente || (this.location.lat && this.location.lng))
    ) {
      // Not editable view, trigger calls
      if (this.switchCodeOrMap) {
        // By Map
        await this.handlerClavePorLatLng()
      } else {
        // By Expediente
        await this.handlerClavePorExpediente()
      }
      if (this.selectedUso) {
        await this.setGirosCentro(this.selectedUso.cve_uso_suelo)
      }
    }

    this.$refs?.ideForm?.resetValidation()
    this.loadingMounted = true
  },
  methods: {
    /** Handler to search by expediente */
    async handlerClavePorExpediente() {
      this.$store.dispatch('loaderFull/open')
      if (!this.obj.schema.disabled) {
        this.$emit('input', null)
      }
      this.noData = false
      this.loading = true

      try {
        if (!this.expediente) return
        if (!this.obj.schema.disabled) this.resetIde() // Editable view, reset values

        const token = await this.generateGrecaptchaToken()
        const expediente =
          this.expediente && this.expediente.startsWith('70')
            ? this.expediente
            : `70${this.expediente}`
        this.claveUso = await getClaveUsoPorExpediente(
          this.$axios,
          token,
          expediente
        )

        await this.setClaveUso()
        this.loading = false
        this.$store.dispatch('loaderFull/close')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error in handlerClavePorExpediente:', error)
        this.loading = false
        this.$store.dispatch('loaderFull/close')
        this.$store.dispatch('notifications/open', {
          color: 'error',
          text: 'Ocurrió un error, intente nuevamente',
        })
      }

      this.loading = false
      this.$store.dispatch('loaderFull/close')
    },
    /** Handler to search by using the map */
    async handlerClavePorLatLng() {
      this.$store.dispatch('loaderFull/open')
      this.loading = true
      this.noData = false
      this.switchCodeOrMap = true
      if (!this.obj.schema.disabled) {
        this.$emit('input', null)
      }
      if (!this.obj.schema.disabled) this.resetIde() // Editable view, reset values
      try {
        const token = await this.generateGrecaptchaToken()
        this.claveUso = await getClaveUsoPorLatLng(
          this.$axios,
          token,
          this.location.lat,
          this.location.lng
        )
        await this.setClaveUso()
        this.loading = false
        this.$store.dispatch('loaderFull/close')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error in handlerClavePorLatLng:', error)
        this.loading = false
        this.$store.dispatch('loaderFull/close')
        this.$store.dispatch('notifications/open', {
          color: 'error',
          text: 'Ocurrió un error, intente nuevamente',
        })
      }
    },
    handlerLandArea() {
      // this.setDensidad()
      this.submitIde()
    },
    async setClaveUso() {
      // No data found
      if (this.claveUso?.features?.length === 0) {
        this.noData = true
        this.$emit('input', null)
        return
      }
      // Set usoSuelos
      this.usoSuelos = this.claveUso?.features[0].properties?.usos_suelo
        ? this.claveUso?.features[0].properties?.usos_suelo
        : []
      this.corredores = this.claveUso?.features[0].properties?.corredores
        ? this.claveUso?.features[0].properties?.corredores
        : []
      // this.densidades = this.claveUso?.features[0].properties?.densidades
      //   ? this.claveUso?.features[0].properties?.densidades
      //   : []
      this.pendientes = this.claveUso?.features[0].properties?.pendientes
        ? this.claveUso?.features[0].properties?.pendientes
        : []
      if (this.corredores.length) {
        const corredores = this.corredores.map((c) => {
          return {
            cve_uso_suelo: c.cve_corredor,
            uso_suelo: c.corredor,
          }
        })
        this.usoSuelos = this.usoSuelos.concat(corredores)
      }

      // Set Map
      if (this.claveUso?.features[0].properties?.punto) {
        const lnglat = this.claveUso?.features[0].properties?.punto
        this.location.lng = parseFloat(lnglat[0])
        this.location.lat = parseFloat(lnglat[1])

        /** Toggle variable to trigger watch on LocationMap.vue and draw the polygon */
        this.updateMap = !this.updateMap
      }

      if (this.claveUso?.features[0].properties?.usos_suelo?.length === 1) {
        // Only one usos_suelos, then preselect option
        if (!this.obj.schema.disabled)
          this.selectedUso =
            this.claveUso?.features[0].properties?.usos_suelo[0]
        // Get available giros + zona conservacion
        await this.setGirosCentro(this.selectedUso.cve_uso_suelo)
        if (this.submitIDE === true) {
          this.submitIde()
          this.submitIDE = false
        }
      }
    },
    // eslint-disable-next-line camelcase
    async setGirosCentro(clave_uso) {
      try {
        if (this.resetGiros === true) {
          this.selectedGiro = []
          this.resetGiros = false
        }
        let token = await this.generateGrecaptchaToken()
        this.giros = await getGirosPorUso(this.$axios, token, clave_uso)
        if (!this.giros || this.giros.length === 0) {
          this.selectedGiro = []
          this.$emit('input', null)
        }

        token = await this.generateGrecaptchaToken()
        this.conservacion = await getZonaConservacion(
          this.$axios,
          token,
          this.location.lat,
          this.location.lng
        )
      } catch (error) {
        this.giros = []
        this.selectedGiro = []
      }
    },
    async setDensidad() {
      let token = ''
      // reset densidad
      this.densidad = null

      // Get the number of the selected giro
      const giro = this.selectedGiro.claveGrupo[0]

      const habitacional = ['HU', 'HM', 'HC', 'HML', 'HMM', 'HMI']
      const comercial = ['ZVC', 'ZVP', 'ZT']
      const corredor = ['CCU', 'CMI', 'CBI']

      if (
        habitacional.includes(this.selectedUso.cve_uso_suelo) &&
        Number(giro) === 1
      ) {
        /**
         * 1- Si caemos en zona_secundaria Habitacional (HU, HM, HC, H todas las H´s) y
            - - el ciudadano selecciona el giro de uso Vivienda (1. 1.2,)
            - - - damos densidad habitacional
          */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadHabitacional(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo,
          this.densidades[0].densidad
        )
      } else if (
        habitacional.includes(this.selectedUso.cve_uso_suelo) &&
        Number(giro) > 1 &&
        Number(giro) <= 3
      ) {
        /**
         * 1- Si caemos en zona_secundaria Habitacional (HU, HM, HC, H todas las H´s) y
         *  - - Si el ciudadano selecciona el giro comercio o servicios (2+ y 3+)
         *    - - - damos densidad de uso comercial y servicios
         */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadComercialesServicios(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo
        )
      } else if (this.selectedUso.cve_uso_suelo === 'CC') {
        /**
         * 2- Si caemos en CC - Crecimiento Controlado
            - - usar pendiente para consultar densidad y lineamientos
         */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadCrecimientoControlado(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo,
          this.pendientes[0].pendiente
        )
      } else if (comercial.includes(this.selectedUso.cve_uso_suelo)) {
        /**
         * 3- Para zonas de Valor Cultural, patrimonial, Industrial y de Transición
            - - tiene que regresar las densidades directas de lineamientos
         */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadComercialesServicios(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo
        )
      } else if (comercial.includes(this.selectedUso.cve_uso_suelo)) {
        /**
         * 3- Para zonas de Valor Cultural, patrimonial, Industrial y de Transición
            - - tiene que regresar las densidades directas de lineamientos
         */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadComercialesServicios(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo,
          'notNeeded'
        )
      } else if (corredor.includes(this.selectedUso.cve_uso_suelo)) {
        /**
         * 4- Para zonas de Corredores urbanos
            - - Si es zona Cultural, Bajo impacto, Mediano impacto
            - - -  regresar las densidades/lineamientos
         */
        token = await this.generateGrecaptchaToken()
        this.densidad = await getDensidadCorredoresUrbanos(
          this.$axios,
          token,
          this.selectedUso.cve_uso_suelo
        )
      } else if (this.selectedUso.cve_uso_suelo === 'CAI') {
        /**
         * 4- Para zonas de Corredores urbanos de alto impacto
            - - preguntar al ciudadano tamaño de terreno
         */
        this.showLandArea = true
        if (this.landArea) {
          token = await this.generateGrecaptchaToken()
          this.densidad = await getDensidadCorredoresUrbanosArea(
            this.$axios,
            token,
            this.selectedUso.cve_uso_suelo,
            this.landArea
          )
        }
      } else if (this.selectedUso.cve_uso_suelo === 'SCU') {
        /**
         * 5- Para zonas de Subcentros Urbanos
            - - preguntar al ciudadano tamaño de terreno
         */
        this.showLandArea = true
        if (this.landArea) {
          token = await this.generateGrecaptchaToken()
          this.densidad = await getDensidadSubcentrosUrbanos(
            this.$axios,
            token,
            this.selectedUso.cve_uso_suelo,
            this.landArea
          )
        }
      }

      this.submitIde()
    },
    /** Reset variables */
    reset() {
      this.expediente = null
      this.location = {
        cp: null,
        state: null,
        city: null,
        neighborhood: null,
        street: null,
        outdoorNumber: null,
        interiorNumber: null,
        reference: null,
        lat: null,
        lng: null,
      }
      this.resetMap = !this.resetMap
      this.$emit('input', null)
      this.noData = false
      this.$refs?.ideForm?.resetValidation()
      this.resetIde()
    },
    resetIde() {
      this.claveUso = null
      this.usoSuelos = []
      this.selectedUso = false

      this.giros = []
      this.selectedGiro = []

      // this.densidad = null
      // this.densidades = null

      this.pendientes = null
      this.pendiente = null

      this.conservacion = null

      this.showLandArea = false
      this.landArea = null
    },
    submitIde() {
      let expediente = ''
      if (this.switchCodeOrMap === true) {
        expediente =
          this.claveUso &&
          this.claveUso.features &&
          this.claveUso.features.length > 0
            ? this.claveUso.features[0].properties.clave_lote
            : ''
      } else {
        expediente =
          this.expediente && this.expediente.startsWith('70')
            ? this.expediente
            : `70${this.expediente}`
      }

      this.$emit('input', {
        switchCodeOrMap: this.switchCodeOrMap,
        expediente,
        // claveUso: this.claveUso,
        // usoSuelos: this.usoSuelos,
        selectedUso: this.selectedUso,

        conservacion: this.conservacion,
        location: this.location,

        // giros: this.giros,
        selectedGiro: this.selectedGiro,

        // corredores: this.corredores,

        // densidades: this.densidades,
        // densidad: this.densidad,

        // pendientes: this.pendientes,
        pendiente: this.pendiente,

        showLandArea: this.showLandArea,
        landArea: this.landArea,
        polygon: JSON.stringify(this.polygon),
      })
    },
    generateGrecaptchaToken() {
      return this.$store.state.grecaptcha.enterprise
        .execute(this.$config.GRECAPTCHA_TOKEN, {
          action: 'verify',
        })
        .then((token) => {
          // eslint-disable-next-line no-console
          return token
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error in generateGrecaptchaToken:', error)
          return error
        })
    },
    clickFilter() {
      this.dialogEditor = true
    },
    setConfig(event) {
      this.configMap = cloneDeep(event)
      if (
        this.$vuetify.breakpoint.lgAndUp === false ||
        (this.obj.schema.documents && this.obj.schema.documents > 0)
      ) {
        this.dialogEditor = false
      }
    },
    changeClave() {
      if (
        this.value !== null &&
        this.value.location !== null &&
        this.value.location.lat
      ) {
        this.resetIde()
      }
    },
  },
}
