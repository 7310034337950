
import Vue from 'vue'
import Vuetify from 'vuetify'
import { mapGetters } from 'vuex'
import Header from '@components/public/Header.vue'

import LoaderFull from '@components/public/LoaderFull.vue'
import LoaderNotify from '@components/public/LoaderNotify.vue'

import Notification from '@/components/public/Notification.vue'
import SidebarEditor from '@/components/UI/SidebarEditor.vue'

import DialogIdle from '@/components/public/DialogIdle.vue'
import SidebarFuncionario from '@/components/sidebar/SidebarFuncionario.vue'
import SidebarCiudadano from '@/components/sidebar/SidebarCiudadano.vue'
Vue.use(Vuetify)

export default Vue.extend({
  components: {
    Header,
    Notification,
    SidebarEditor,
    LoaderNotify,
    LoaderFull,
    DialogIdle,
    SidebarFuncionario,
    SidebarCiudadano,
  },
  middleware: ['authenticated', 'hasUpdatedPassword'],
  loading: true,
  data() {
    return {
      /** Seems @deprecated  */
      fixed: false,
      /** Holds the firebase project */
      project_id: this.$config.FB_PROJECT_ID,
      /** Flag to wait until the brand settings are loaded  */
      brandReady: false,
      /** Flag to wait until the landing texts are loaded */
      landingTextReady: false,
      /**
       * TBC with @author @Pam
       * Flag to show Header, Footer based on url certs?
       * */
      showElement: true,
      /** Flag to show/hide sidebar */
      drawerSidebar: false,
      /** Hold window size to control the size of the sidebar to render */
      windowSize: {
        x: 0,
        y: 0,
      },
      /** Hold user role / profiel */
      typeProfile: '',
      /** Flag to control the size of the sidebar to render */
      mini: true,
    }
  },
  /** Seems @deprecated, TBC with @Pam */
  head() {
    return {
      title: this.seoSettings?.title,
      meta: [
        {
          hid: 'description', // Id
          name: 'description', // Meta name
          content: `${this.seoSettings?.description}`, // Description
        },
      ],
      titleTemplate: '%s',
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.seoSettings?.favicon,
        },
      ],
    }
  },
  computed: {
    /** compute brand, landingtext and user */
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      seoSettings: 'setting/seoSettings',
      landingtext: 'landingtext/landingtext',
    }),
    /**
     * Getter for user
     * TBC with @Sule - No se está sobre escribiendo el user en ...mapGetters?
     */
    user() {
      return this.$store.state.users.user
    },
    /** Flag for brand and landingtext status */
    dataReady() {
      return this.brandReady && this.landingTextReady
    },
    /** Flag to control if the user is idle */
    /** @todo - validate with @Navs */
    isIdle() {
      return this.isAppIdle
    },
    paddingMain() {
      return (this.$config.FB_PROJECT_ID === 'os-sandbox-mty' ||
        this.$config.FB_PROJECT_ID === 'os-monterrey') &&
        !this.$route.fullPath.includes('/admin/')
        ? 'padding: 120px 0px 0px 0px!important'
        : 'padding: 80px 0px 0px 0px!important'
    },
  },
  watch: {
    /** Show / hide sidebar based on typeProfile  */
    typeProfile(value) {
      if (value === 'Funcionario' && this.showElement) {
        this.drawerSidebar = true
      } else {
        this.drawerSidebar = false
      }
    },
    /** If brand loaded, change flag */
    brand(value) {
      if (value.loaded) this.brandReady = true
    },
    /** If landingtext loaded, change flag */
    landingtext(value) {
      if (value.loaded) this.landingTextReady = true
    },
    /** If idle show dialog */
    isIdle(value) {
      if (
        this.$store.getters.loggedIn &&
        this.$config.NODE_ENV !== 'development'
      ) {
        if (value === true) {
          this.$store.dispatch('dialogIdle/open')
        }
      }
    },
    /** if user changed, update typeProfile */
    user(val) {
      this.typeProfile = val.typeProfile
    },
    /** update sidebar status on store */
    drawerSidebar(value) {
      this.$store.commit('brand/SET_DRAWER_SIDEBAR', value)
    },
    /** update sidebar mini status on store */
    mini(value) {
      this.$store.commit('brand/SET_SIDEBAR_MINI', value)
    },
    $route(to) {
      this.setTheme(to)
    },
  },
  beforeMount() {
    /**
     * @todo validate with @Pam
     * Show elements if not on certs view?
     * */
    if (Object.keys(this.$route.query).includes('type')) {
      this.showElement = this.$route.query.type !== 'cert'
    }
  },
  async mounted() {
    if (this.brand && this.brand.loaded === true) this.brandReady = true
    if (this.landingtext && this.landingtext.loaded === true)
      this.landingTextReady = true
    /** @todo Validate with @Navs */
    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => this.$nuxt.$loading.finish(), 500)
    })
    /**
     * @todo
     * Move logic to mixin and refactor code
     * Should we have a brand setting for this?
     */
    /* if (
      this.$config.FB_PROJECT_ID === 'unicef-certs' ||
      this.$config.FB_PROJECT_ID === 'undp-f9c4e'
    ) {
      await this.$i18n.setLocale('en')
      this.$vuetify.lang.current = 'en'
    } else if (
      this.$config.FB_PROJECT_ID === 'os-gobierno-de-corrientes' ||
      this.$config.FB_PROJECT_ID === 'os-gobierno-de-nuevo-leon' ||
      this.$config.FB_PROJECT_ID === 'demosburg-dev' ||
      this.$config.FB_PROJECT_ID === 'os-udg'
    ) {
      await this.$i18n.setLocale('es')
      this.$vuetify.lang.current = 'es'
    } else if (
      this.$config.FB_PROJECT_ID === 'tramites-monterrey' ||
      this.$config.FB_PROJECT_ID === 'os-sandbox-mty' ||
      this.$config.FB_PROJECT_ID === 'os-monterrey'
    ) {
      await this.$i18n.setLocale('es-MTY')
      this.$vuetify.lang.current = 'es-MTY'
    } else {
      await this.$i18n.setLocale('es-AR')
      this.$vuetify.lang.current = 'es-AR'
    } */
    const locale = this.brand?.locale ? this.brand?.locale : 'es'
    await this.$i18n.setLocale(locale)
    this.$vuetify.lang.current = locale
    this.redimensionar()
    if (this.user.uid) {
      this.typeProfile = this.user.typeProfile
    }
    this.setTheme(this.$route)
  },
  methods: {
    setTheme(to) {
      if (!to.fullPath.includes('/admin/')) {
        for (const [key, value] of Object.entries(this.brand.theme)) {
          // eslint-disable-next-line import/no-named-as-default-member
          Vue.set(this.$vuetify.theme.themes.light, key, value.slice(0, -2))
        }
      } else {
        this.$vuetify.theme.themes.light = {
          ...this.$vuetify.theme.defaults.light,
        }
      }
    },
    /** Show / hide sidebar and control for size type */
    setDrawer(event) {
      if (this.windowSize.x >= 960) {
        this.mini = !this.mini
      } else {
        this.drawerSidebar = event
        this.mini = false
      }
    },
    /**
     * @todo change to english
     * Determines the type of sidebar to show based on window size
     */
    redimensionar() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      if (this.windowSize.x >= 960) {
        this.mini = true
        if (this.drawerSidebar !== true) {
          // this.drawerSidebar = true
        }
      } else if (this.windowSize.x < 960) {
        this.mini = false
      }
    },
    /**
     * @todo change to english
     * Validate use cases with @Sule
     */
    expandirSidebar() {
      this.mini = false
    },
  },
})
