import { cloneDeep } from 'lodash'
import { getLastBrand } from '@/services/Brand'

export const state = () => ({
  brand: {
    app_name: '',
    drawerSidebar: false,
    sidebarMini: true,
    city_splash_primary:
      'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/brand%2Fimages%2Fdefault_splash_primary.png?alt=media&token=b4feafa8-08ba-4396-9fc0-1fbbed2cf5e4',
    city_splash_secondary:
      'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/brand%2Fimages%2Fdefault_splash_secondary.png?alt=media&token=616244e7-e306-498a-a3b3-3d138c15f10f',
    city_splash_login: '',
    city_url: 'demo.os.city',
    city_name: '',
    copyright_footer: '',
    favicon: '',
    logo: null,
    logo_provincia_misiones: null,
    theme: {
      primary: '#061E47',
      secondary: '#053F6F',
      accent: '#2196F3',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      title: '',
      subtitle: '',
      text: '',
      btnPrimary: '',
      btnSecondary: '',
      chipsPost: '',
      cardBackground: '',
      cardBorder: '',
      cardTitle: '',
      cardToolbar: '',
      tabBorder: '',
      tabText: '',
      tabBackground: '',
      tabActive: '',
      tabDisabled: '',
      icon: '',
      iconFicha: '',
      titleCard: '',
      tabsHome: '',
      listHome: '',
    },
    header_color: '#ffffff',
    footer_color: '#ffffff',
    footer_contact_button_color: '',
    footer_background_type: 'lineal',
    footer_bg_primary: '',
    footer_bg_secondary: '',
    footer_logo: '',
    footer_text_color: '',
    heightLogo: '40px',
    additional_color: '#d6d6d6',
    about_title: 'Acerca de',
    about_content: '',
    contact_title: 'Contacto',
    contact_info: '',
    hero_bg_primary: '#5d306f',
    hero_bg_secondary: '#bd0b4d',
    terms: false,
    privacy: false,
    termsAndConditions: '',
    privacyPolicy: '',
    needsInfo: true,
    buttonColorLogin: '#43475C',
    mobileStepThree: '',
    firstImageStepThree: '',
    secondImageStepThree: '',
    thirdImageStepThree: '',
    header_bg_primary: '#0096da',
    header_bg_secondary: '#035d98',
    header_button_color: '#0088CC',
    header_background_type: 'lineal',
    errorImage: '',
    hero_background_type: 'lineal',
    hero_bg_image: '',
    levelUpImage: '',
    validateLevelImage: '',
    qrImage: '',
    loaded: false,
    avatar_text_color: '#FFFFFF',
    playstoreDownload:
      'https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png',
    playstoreLink:
      'https://play.google.com/store/apps/details?id=com.oscity.oswallet',
    appStoreDowload:
      'https://firebasestorage.googleapis.com/v0/b/mar-de-plata.appspot.com/o/brand%2FAppStoreDowload.png?alt=media&token=347bc4b6-4a18-42a3-88e7-e4056aa32ab3',
    appStoreLink:
      'https://apps.apple.com/app/soberana-by-onesmart/id1591634290',
    locale: 'es',
    quarkId: {
      outputDescriptorCitizen: {
        display: {
          title: {
            text: 'Ciudadano',
          },
          subtitle: {
            text: 'Estado',
          },
          description: {
            text: 'Credencial emitida',
          },
        },
      },
      styles: {
        background: {
          color: '#0b1f45',
        },
        thumbnail: {
          uri: 'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/soberanaIssuer%2FLogoIssuerCMPSoberana.png?alt=media&token=6c07660a-61b9-4d1b-b299-86e622992391',
          alt: 'OS City',
        },
        hero: {
          uri: 'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/soberanaIssuer%2FHeroIssuerCMPSoberana.png?alt=media&token=c7f332ae-c270-4a78-8df9-fe99dcee7c76',
          alt: 'OS City',
        },
        text: {
          color: '#ffffff',
        },
      },
      issuer: {
        name: 'OS City',
        styles: {
          thumbnail: {
            uri: 'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/soberanaIssuer%2FLogoIssuerCMPSoberana.png?alt=media&token=6c07660a-61b9-4d1b-b299-86e622992391',
            alt: 'OS City',
          },
          hero: {
            uri: 'https://firebasestorage.googleapis.com/v0/b/demosburg-dev.appspot.com/o/soberanaIssuer%2FHeroIssuerCMPSoberana.png?alt=media&token=c7f332ae-c270-4a78-8df9-fe99dcee7c76',
            alt: 'OS City',
          },
          background: {
            color: '#0b1f45',
          },
          text: {
            color: '#d4d400',
          },
        },
      },
    },
  },
})

export const mutations = {
  SET_BRAND(state, brand) {
    state.brand = brand
  },
  SET_DRAWER_SIDEBAR(state, drawer) {
    state.brand.drawerSidebar = drawer
  },
  SET_SIDEBAR_MINI(state, mini) {
    state.brand.sidebarMini = mini
  },
  UPDATE_ELEMENT(state, { element, data }) {
    state.brand = { ...state.brand, [element]: data }
  },
}

export const actions = {
  async get({ commit, rootState }) {
    await getLastBrand(this.$fire).then((result) => {
      const brand = cloneDeep(rootState.brand.brand)
      if (!result.empty) {
        const data = {
          ...result.docs[0].data(),
          id: result.docs[0].id,
        }
        commit('SET_BRAND', { ...brand, ...data, loaded: true })
      }
    })
  },
}

export const getters = {
  brand(state) {
    return state.brand
  },
  needsInfo(state) {
    return state.brand.needsInfo
  },
  logo(state) {
    return state.brand.logo
  },
  logoProvinciaMisiones(state) {
    return state.brand.logo_provincia_misiones
  },
  privacy(state) {
    return state.brand.privacy
  },
  terms(state) {
    return state.brand.terms
  },
  buttonColorLogin(state) {
    return state.brand.buttonColorLogin
  },
  logoFooter(state) {
    return state.brand.footer_logo
  },
  getHeaderColor(state) {
    return state.brand.header_color
  },
  getFooterColor(state) {
    return state.brand.footer_color
  },
  getFooterButtonColor(state) {
    return state.brand.footer_contact_button_color
  },
  getFooterTextColor(state) {
    return state.brand.textFooterColor
  },
  getHeightLogo(state) {
    return state.brand.heightLogo
  },
  getPrimaryColor(state) {
    return state.brand.primary_color
  },
  getSecondaryColor(state) {
    return state.brand.secondary_color
  },
  getHeroPrimaryColor(state) {
    return state.brand.hero_bg_primary
  },
  getHeroSecondaryColor(state) {
    return state.brand.hero_bg_secondary
  },
  getMobileStepThree(state) {
    return state.brand.mobileStepThree
  },
  getFirstImageStepThree(state) {
    return state.brand.firstImageStepThree
  },
  getSecondImageStepThree(state) {
    return state.brand.secondImageStepThree
  },
  getThirdImageStepThree(state) {
    return state.brand.thirdImageStepThree
  },
  getButtonHeaderColor(state) {
    return state.brand.header_button_color
  },
  getErrorImage(state) {
    return state.brand.errorImage
  },
  getLevelUpImage(state) {
    return state.brand.levelUpImage
  },
  getValidateLevelImage(state) {
    return state.brand.validateLevelImage
  },
  getAvatarTextColor(state) {
    return state.brand.avatar_text_color
  },
  getDrawerSidebar(state) {
    return state.brand.drawerSidebar
  },
  getSidebarMini(state) {
    return state.brand.sidebarMini
  },
  getLocale(state) {
    return state.brand.locale
  },
}
