
import CancelIcon from '~/components/icons/CancelIcon.vue'

export default {
  components: {
    CancelIcon,
  },
  data() {
    return {}
  },
  async mounted() {
    if (
      this.$config.FB_PROJECT_ID === 'unicef-certs' ||
      this.$config.FB_PROJECT_ID === 'undp-f9c4e'
    ) {
      await this.$i18n.setLocale('en')
      this.$vuetify.lang.current = 'en'
    } else if (
      this.$config.FB_PROJECT_ID === 'tramites-monterrey' ||
      this.$config.FB_PROJECT_ID === 'demosburg-dev' ||
      this.$config.FB_PROJECT_ID === 'os-sandbox-mty'
    ) {
      await this.$i18n.setLocale('es')
      this.$vuetify.lang.current = 'es'
    } else {
      await this.$i18n.setLocale('es-AR')
      this.$vuetify.lang.current = 'es-AR'
    }
  },
  methods: {},
}
