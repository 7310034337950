
import { mapState, mapGetters, mapMutations } from 'vuex'

import includes from 'lodash/includes'
import cloneDeep from 'lodash/cloneDeep'
import difference from 'lodash/difference'
import get from 'lodash/get'
import { format } from 'date-fns'
// import draggable from 'vuedraggable'
import firebase from 'firebase/compat/app'
import { updateBrand, updatePostsListBrand } from '~/services/Brand.ts'
import { setUserRole } from '@/services/User'
import { updateLandingText } from '~/services/LandingText.ts'
import { updateSettings } from '~/services/Settings.ts'

export default {
  name: 'SidebarEditor',
  // components: { EditorContent, MenuBar, TextEditor, draggable },
  data() {
    return {
      loading: false,
      theme: this.$config.THEME,
      themeBPostsValues: {},
      imageUpdatePath: null,
      dialogSimpleImage: false,
      tabs: null,
      imageSrc: null,
      imageFile: null,
      elementImage: null,
      imageIndex: null,
      headerSection: {
        id: 'header',
        title: 'Header',
        icon: 'mdi-page-layout-header',
      },
      footerSection: {
        id: 'footer',
        title: 'Footer',
        icon: 'mdi-page-layout-footer',
      },
      usersSection: {
        id: 'users',
        title: 'Comm users',
        icon: 'mdi-account-plus',
      },
      themeBPostsHero: {
        id: 'themeBPosts',
        title: 'Hero',
        icon: 'mdi-page-layout-body',
      },
      homeSections: [
        {
          id: 'home_firstSection',
          title: 'First Section',
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'home_secondSection',
          title: 'Second Section',
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'home_thirdSection',
          title: 'Third Section',
          icon: 'mdi-page-layout-body',
        },
      ],
      newHomeSections: [
        {
          id: 'newHome_firstSection',
          title: this.$t('editor.firstSection'),
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'newHome_secondSection',
          title: this.$t('editor.secondSection'),
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'newHome_sixthSection',
          title: this.$t('editor.thirdSection'),
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'newHome_fifthSection',
          title: this.$t('editor.fourthSection'),
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'newHome_thirdSection',
          title: this.$t('editor.fifthSection'),
          icon: 'mdi-page-layout-body',
        },
        {
          id: 'newHome_fourthSection',
          title: this.$t('editor.sixthSection'),
          icon: 'mdi-page-layout-body',
        },
      ],
      backgroundTypes: [
        { type: 'lineal', text: this.$t('editor.linear_color') },
        { type: 'gradient', text: this.$t('editor.gradient_color') },
        { type: 'image', text: this.$t('editor.image') },
      ],
      backgroundTypesHeader: [
        { type: 'lineal', text: this.$t('editor.linear_color') },
        { type: 'gradient', text: this.$t('editor.gradient_color') },
      ],
      errors: {
        hero_bg_image: false,
      },
      data: [],
      communicators: [],
      previousCommunicators: [],
      items: [],
      filtersIds: [],
      filtersWithTerms: [],
    }
  },
  computed: {
    openEditor() {
      return this.$store.state.ui.openEditor
    },

    ...mapState({
      brand: (state) => state.brand.brand,
      landingText: (state) => state.landingtext.landingtext,
      settings: (state) => state.setting.setting,
      filters: (state) => state.filters.filters,
    }),
    ...mapGetters({
      activeModules: 'modules/getActiveModules',
      user: 'users/user',
    }),
    modulesOptions() {
      return this.activeModules.map((module) => {
        return {
          title: module.title,
          id: module.id,
        }
      })
    },
    submodulesOptions() {
      const selectedModules = this.landingText.home.secondSection.modules
      if (this.activeModules.length > 0) {
        const submodulesOptions = []
        this.activeModules.forEach((module) => {
          if (selectedModules.includes(module.id))
            module.submodules.forEach((submodule) => {
              if (submodule.active) {
                submodulesOptions.push({
                  title: submodule.title,
                  submoduleId: submodule.slug,
                  moduleId: module.id,
                })
              }
            })
        })
        return submodulesOptions
      } else {
        return []
      }
    },
    sections() {
      // const currentPath = this.$route.path
      // return currentPath === '/'
      //   ? [this.headerSection, ...this.homeSections, this.footerSection]
      //   : currentPath === '/home'
      //   ? [this.headerSection, ...this.newHomeSections, this.footerSection]
      //   : [this.headerSection, this.footerSection]
      const theme = this.$config.THEME
      return theme === 'Britannia' && this.$route.path === '/'
        ? [this.headerSection, ...this.homeSections, this.footerSection]
        : (theme === 'Andromeda' || theme === 'Monterrey' || !theme) &&
          (this.$route.path === '/' || this.$route.path === '/home') &&
          this.user.role === 'superadmin'
        ? [
            this.headerSection,
            ...this.newHomeSections,
            this.footerSection,
            this.usersSection,
          ]
        : (theme === 'Andromeda' || theme === 'Monterrey' || !theme) &&
          (this.$route.path === '/' || this.$route.path === '/home')
        ? [this.headerSection, ...this.newHomeSections, this.footerSection]
        : theme === 'NuevoLeon' &&
          (this.$route.path === '/' || this.$route.path === '/home')
        ? [this.headerSection, ...this.newHomeSections, this.footerSection]
        : theme === 'Britannia' &&
          // eslint-disable-next-line no-useless-escape
          /^\/modules\/\w+\/\w+(\/)?$/.test(this.$route.path)
        ? [this.headerSection, this.themeBPostsHero, this.footerSection]
        : [this.headerSection, this.footerSection]
    },
    validUpload() {
      if (this.tabs === 'tab-0') {
        return this.validImage && this.imageSrc !== null
      } else {
        return this.imageFile !== null && this.validImageFile
      }
    },
    validImage() {
      return (
        this.imageSrc?.match(/unsplash/) !== null ||
        this.imageSrc?.match(/\.(jpeg|jpg|gif|png)$/) !== null
      )
    },
    validImageFile() {
      return (
        this.imageFile?.size < 5000000 &&
        ['image/png', 'image/jpeg', 'image/bmp', 'image/jpeg'].includes(
          this.imageFile?.type
        )
      )
    },
  },

  watch: {
    $route(to) {
      if (/^\/modules\/\w+\/\w+(\/)?$/.test(to.path)) {
        this.getListPostsBrand()
      }
    },
    /* A watch is implemented to detect the first time the sidebar is opened and trigger
    the query to retrieve the users. In this way, we ensure that the query is only executed
    when a user with permissions to view the sidebar opens it, avoiding unnecessary
    execution if it is not opened.
    */
    async openEditor(val) {
      if (val === true && this.data && this.data.length === 0)
        await this.getUsers()
    },
  },

  async mounted() {
    if (/^\/modules\/\w+\/\w+(\/)?$/.test(this.$route.path)) {
      await this.getListPostsBrand()
    }
  },

  methods: {
    remove(item) {
      this.communicators = this.communicators.filter(
        (communicator) => communicator.uid !== item.uid
      )
    },
    cleanFirstName(firstName) {
      if (firstName.includes(' ')) {
        return firstName.split(' ')[0]
      } else {
        return firstName
      }
    },
    // Función que le da formato a una fecha
    formatDate(val) {
      if (val && val != null) {
        const isValidDate = Date.parse(val)
        if (!isNaN(isValidDate)) {
          val = new Date(val)
          return format(new Date(val), 'dd/MM/yyyy HH:mm:ss')
        } else {
          return format(
            new Date((val.seconds || val._seconds) * 1000),
            'dd/MM/yyyy HH:mm:ss'
          )
        }
      }
    },
    async getUsers() {
      this.$store.dispatch('loaderFull/open')
      this.loading = true

      // The query is changed to ensure that it only retrieves users who are employees and omits non-employees.
      await this.$fire.firestore
        .collection('users')
        .where('role', '!=', 'user')
        .get()
        .then((res) => {
          this.data = []
          res.forEach((doc) => {
            this.data.push(doc.data())
            if (includes(['communicator'], doc.data().role)) {
              this.communicators.push({
                ...doc.data(),
              })
              this.previousCommunicators = cloneDeep(this.communicators)
            }
          })
          this.data = this.data.map((element) => {
            return {
              ...element,
              created: this.formatDate(element.created),
              activated: element.activated ? 'Sí' : 'No',
            }
          })
          this.$store.dispatch('loaderFull/close')
        })
        .catch((error) => {
          this.$store.dispatch('loaderFull/close')
          this.$store.dispatch('notifications/open', {
            color: 'error',
            text: this.$t('errorGeneric'),
          })
          // eslint-disable-next-line no-console
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getListPostsBrand() {
      await this.$fire.firestore
        .collection('modules')
        .doc(this.$route.params.module)
        .collection(this.$route.params.submodule)
        .doc('settings')
        .get()
        .then((doc) => {
          this.themeBPostsValues = { ...doc.data() }
        })
    },
    toggleEditor() {
      this.$store.dispatch('ui/toggleEditor')
    },
    ...mapMutations({
      updateBrand_element: 'brand/UPDATE_ELEMENT',
      updateLandingText_element: 'landingtext/UPDATE_ELEMENT',
      updateSettings_element: 'setting/UPDATE_ELEMENT',
    }),
    // changeIndex({ oldIndex, newIndex },element) {
    changeIndex(event, element) {
      // const { oldIndex, newIndex } = event.moved
      // console.log(oldIndex, newIndex)
      // eslint-disable-next-line no-console
      console.log(event)
      // eslint-disable-next-line no-console
      console.log(element)
      // const item = this.submodule.questions.splice(oldIndex, 1)[0]
      // this.submodule.questions.splice(newIndex, 0, item)
    },
    async searchInfoSubmodule(moduleID, submoduleID) {
      const modules = this.$store.getters['modules/getModules']
      let info = null
      await modules.forEach((mod) => {
        if (mod.id === moduleID) {
          const submodules = mod.submodules
          submodules.forEach((submod) => {
            if (submod.slug === submoduleID) {
              info = submod
            }
          })
        }
      })
      return info
    },
    async getSubmodulePosts(moduleId, submoduleId) {
      const posts = []
      await this.$fire.firestore
        .collection('modules')
        .doc(moduleId)
        .collection(submoduleId)
        .where('config.showOnHomePage', '==', true)
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach(async (doc) => {
            const d = await this.searchInfoSubmodule(moduleId, submoduleId)
            if (doc.data().config.showOnHomePage === true) {
              posts.push({
                id: doc.id,
                moduleId,
                submoduleId,
                description: doc.data().description,
                title: doc.data().title,
                titleSubmoduleId: d ? d.title : null,
                configPath: doc.data().configPath || {},
              })
            }
          })
        })
      return posts
    },
    async saveLandingPosts() {
      let posts = []
      if (
        this.landingText.home.secondSection.modules.length > 0 &&
        this.landingText.home.secondSection.submodules.length > 0
      ) {
        for (const submodule of this.landingText.home.secondSection
          .submodules) {
          const submodulePosts = await this.getSubmodulePosts(
            submodule.moduleId,
            submodule.submoduleId
          )
          posts = [...posts, ...submodulePosts]
        }
      }
      this.updateLandingText_element({
        element: 'home.secondSection.posts',
        data: posts,
      })
    },
    async insertPicture() {
      this.$store.dispatch('loaderFull/open')
      let url = null

      if (this.tabs === 'tab-0') {
        url = this.imageSrc
      } else {
        const storageRef = this.$fire.storage.ref()
        const rename =
          this.imageFile.name.split('.').shift() +
          '_time' +
          new Date().getTime()
        await storageRef
          .child(
            `brand/userImages/${rename}.${this.imageFile.name.split('.').pop()}`
          )
          .put(this.imageFile)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((downloadURL) => {
              url = downloadURL
            })
          })
      }

      if (this.imageUpdatePath === 'brand') {
        this.updateBrand_element({ element: this.elementImage, data: url })
      } else if (this.imageUpdatePath === 'landingText') {
        if (this.imageIndex !== null) {
          this.updateLandingText_element({
            element: this.elementImage,
            data: url,
            index: this.imageIndex,
            subelement: 'src',
          })
        } else {
          this.updateLandingText_element({
            element: this.elementImage,
            data: url,
          })
        }
      } else if (this.imageUpdatePath === 'themeBPosts') {
        this.themeBPostsValues[this.elementImage] = url
      }
      url = null
      this.dialogSimpleImage = false
      this.cleanInputs()
      this.$store.dispatch('loaderFull/close')
    },
    cleanInputs() {
      this.imageSrc = null
      this.imageFile = null
      this.elementImage = null
      this.imageUpdatePath = null
      this.imageIndex = null
    },
    cleanUndefined(item) {
      if (item) {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === 'object') {
            this.cleanUndefined(item[key])
          }
          if (item[key] === undefined) {
            item[key] = null
          }
        })
      }
      return item
    },
    async uploadBrand() {
      const updatedByUid = this.$store.state.users.user.uid
      const updatedByEmail = this.$store.state.users.user.email
      const updatedAt = firebase.firestore.Timestamp.now()
      try {
        this.$store.dispatch('loaderFull/open')
        const landingTextLocal = cloneDeep(this.landingText)
        const cleanedLandingText = this.cleanUndefined(landingTextLocal)
        await updateBrand(this.$fire, this.brand.id, {
          ...this.brand,
          updatedByUid,
          updatedByEmail,
          updatedAt,
          logChanges: true,
        })
        await updateSettings(this.$fire, this.settings.id, {
          ...this.settings,
          updatedByUid,
          updatedByEmail,
          updatedAt,
          logChanges: true,
        })
        await updateLandingText(this.$fire, this.landingText.id, {
          ...cleanedLandingText,
          updatedByUid,
          updatedByEmail,
          updatedAt,
          logChanges: true,
        })
        if (/^\/modules\/\w+\/\w+(\/)?$/.test(this.$route.path)) {
          await updatePostsListBrand(
            this.$fire,
            this.$route.params.module,
            this.$route.params.submodule,
            {
              ...this.themeBPostsValues,
              updatedByUid,
              updatedByEmail,
              updatedAt,
              logChanges: true,
            }
          )
          // this.$root.$emit('changedBrandListPosts', this.themeBPostsValues)
        }
        await this.actualizarOperadores()
        this.$store.dispatch('loaderFull/close')
        this.$store.dispatch('notifications/open', {
          color: 'success',
          text: this.$t('editor.settings-have-been-saved-successfully'),
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        this.$store.dispatch('loaderFull/close')
        this.$store.dispatch('notifications/open', {
          color: 'error',
          text: this.$t('editor.an-error-has-occurred-please-try-again'),
        })
      }
    },
    imageLoadError(target) {
      this.errors[target] = true
    },
    async actualizarOperadores() {
      if (this.communicators.length > 0) {
        for (const i in this.communicators) {
          await setUserRole(this.$fire, this.communicators[i].uid, true)
        }
        const communicatorsId = this.communicators.map((commuser) => {
          return commuser.uid
        })
        const previousCommunicatorsId = this.previousCommunicators.map(
          (commuser) => {
            return commuser.uid
          }
        )
        const notComms = difference(previousCommunicatorsId, communicatorsId)
        if (notComms.length > 0) {
          for (const i in notComms) {
            await setUserRole(this.$fire, notComms[i], false)
          }
        }
      } else if (
        !this.communicators.length > 0 &&
        this.previousCommunicators?.length > 0
      ) {
        for (const i in this.previousCommunicators) {
          await setUserRole(
            this.$fire,
            this.previousCommunicators[i].uid,
            false
          )
        }
      }
    },
    addImage(element) {
      const newImage = {
        src: '',
        url: '',
        isInternalUrl: false,
      }
      const images = get(this.landingText, element)
      this.updateLandingText_element({ element, data: [...images, newImage] })
    },
    removeImage(element, index) {
      const images = get(this.landingText, element)
      const newImages = [...images]
      newImages.splice(index, 1)
      this.updateLandingText_element({ element, data: newImages })
    },
  },
}
