
import Vue from 'vue'
import { mapState } from 'vuex'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { logOut } from '@/services/Firebase'
import { getIdReferenceFromDigitalIdentity } from '@/services/Certificates'

export default Vue.extend({
  name: 'AvatarMenuCompanies',
  props: {
    withPadding: {
      type: Boolean,
      default() {
        return false
      },
    },
    sidebar: {
      type: Boolean,
      default() {
        return false
      },
    },
    dataCy: {
      type: String,
      default: '',
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id_reference: '',
      userIsRegistered: false,
      userHavePublicAddress: false,
      logoutLoading: false,
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      checkRole: '',
      projectId: this.$config.FB_PROJECT_ID,
      isMTYInstance:
        this.$config.FB_PROJECT_ID === 'os-sandbox-mty' ||
        this.$config.FB_PROJECT_ID === 'os-monterrey',
      region: this.$config.REGION || 'us-central1',
    }
  },
  computed: {
    ...mapState({
      modules: (state: any) => state.modules.modules,
      user: (state: any) => state.users.user,
    }),
    userName(): string {
      return this.user.name
    },
  },
  watch: {
    user: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(newVal, oldVal) {
        if (newVal) {
          this.verifyUserRegistration(newVal.uid)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (this.user?.uid) {
      await this.verifyUserRegistration(this.user.uid)
    }
  },
  methods: {
    returnOriginalProfile() {
      this.$store.dispatch('loaderFull/open')
      try {
        this.$store.commit('users/SET_USER', {
          ...this.user.originalUser,
          company: false,
          isProfileChange: true,
        })
        localStorage.removeItem('currentProfile')
        this.$router.push({
          path: '/expediente/identidad',
        })
      } catch (errorCatch) {
        // eslint-disable-next-line no-console
        console.error(
          'Error try returnOriginalProfile MenCompanies: ',
          errorCatch
        )
      } finally {
        this.$store.dispatch('loaderFull/close')
      }
    },
    setUserName(user: {
      // eslint-disable-next-line camelcase
      first_name: string
      // eslint-disable-next-line camelcase
      last_name: string
      // eslint-disable-next-line camelcase
      last_name2: string
    }) {
      let userName =
        user?.first_name?.split(' ')[0] +
        ' ' +
        user?.last_name +
        (user?.last_name2 ? ' ' + user?.last_name2 : '')

      if (userName.length > 20) {
        userName = userName.slice(0, 18) + '...'
      }
      return userName
    },
    async getIdReferenceFromDigitalIdentity() {
      try {
        if (this.user?.uid) {
          const idReference = await getIdReferenceFromDigitalIdentity(
            this.$fire,
            this.user.uid
          )
          this.id_reference = idReference?.docs[0]
            ?.data()
            ?.link.split('/')
            .slice(-1)
          if (this.id_reference) {
            this.$router.push({
              path: `/citizen/certificates/digital-identity/${this.id_reference}`,
            })
          } else {
            this.$router.push({
              path: '/citizen/certificates/digital-identity/',
            })
          }
        }
      } catch (error) {
        this.$router.push({
          path: '/citizen/certificates/digital-identity/',
        })
        /* this.$store.dispatch('notifications/open', {
          color: 'error',
          text: this.$t('errorGetCertificate'),
        }) */
      }
    },
    logoutIf() {
      if (
        this.$route.path !== '/complete-data' &&
        this.$route.path !== '/CompleteData'
      ) {
        this.logout()
      } else {
        this.$router.push({ path: '/' })
        this.$root.$on('logout', (valor: boolean) => {
          if (valor) {
            this.logout()
          }
        })
      }
    },
    expediente() {
      this.$router.push({ path: '/expediente' })
    },
    async logout() {
      try {
        const self = this
        this.logoutLoading = true
        await logOut(this.$fire)
        const reino = localStorage.getItem('reino')
        if (reino) {
          await self.$autenticar.logout()
          localStorage.removeItem('reino')
          localStorage.removeItem('backToReino')
          localStorage.removeItem('levelUpdated')
        }
        this.$emit('close-drawer', false)
        // Remove form storage the company profile
        localStorage.removeItem('currentProfile')
        await this.$store.commit('users/LOGOUT_USER')
        await this.$store.dispatch('users/fetchUser')
        this.$store.dispatch('setloggedIn', false)
        if (
          this.$route.path !== '/complete-data' &&
          this.$route.path !== '/CompleteData'
        ) {
          this.$router.push({ path: '/' })
        }
        this.logoutLoading = false
      } catch (error) {
        this.logoutLoading = false
        this.snackbar.show = true
        this.snackbar.text = 'Ocurrió un error al cerrar sesión'
        this.snackbar.color = 'error'
      }
    },
    async verifyUserRegistration(uid: string) {
      const functions = getFunctions(getApp(), this.region)
      const userEmailExist = httpsCallable(functions, 'users-userEmailExist')
      if (this.user?.uid) {
        try {
          let resp = {} as any
          await userEmailExist({ uid }).then((result) => {
            resp = result.data
          })
          const email = resp.user.email
          const emailValidation = email !== null && email !== ''
          const providerValidation = resp.user.providerData.length > 0
          this.userIsRegistered = emailValidation && providerValidation
        } catch {
          this.userIsRegistered = false
        }
      }
      if (this.user.public_address) {
        this.userHavePublicAddress = true
      } else {
        this.userHavePublicAddress = false
      }
    },
  },
})
