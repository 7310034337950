import { render, staticRenderFns } from "./SidebarFuncionario.vue?vue&type=template&id=6ba56295&scoped=true&"
import script from "./SidebarFuncionario.vue?vue&type=script&lang=js&"
export * from "./SidebarFuncionario.vue?vue&type=script&lang=js&"
import style0 from "./SidebarFuncionario.vue?vue&type=style&index=0&id=6ba56295&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba56295",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicLogo: require('/opt/atlassian/pipelines/agent/build/components/public/Logo.vue').default,IconsSidebarModuleIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/ModuleIcon.vue').default,IconsFormFillInspectorIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/formFill/InspectorIcon.vue').default,IconsFormFillNotificatorIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/formFill/NotificatorIcon.vue').default,IconsPaymentsCardPayIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/payments/CardPayIcon.vue').default,IconsCitasCalendarioIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/citas/CalendarioIcon.vue').default,IconsSidebarUserIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/UserIcon.vue').default,IconsSidebarCatalogosIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/CatalogosIcon.vue').default,IconsSidebarFilterIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/FilterIcon.vue').default,IconsSidebarConfiguracionIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/ConfiguracionIcon.vue').default,IconsSidebarEstadisticasIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/EstadisticasIcon.vue').default,IconsSidebarOSCityIcon: require('/opt/atlassian/pipelines/agent/build/components/icons/sidebar/OSCityIcon.vue').default})
