import { merge, cloneDeep, set } from 'lodash'
import { getLastLandingText } from '@/services/LandingText'

export const state = () => ({
  landingtext: {
    home: {
      firstSection: {
        active: true,
        text: '',
        quickLinks: [],
      },
      secondSection: {
        active: true,
        title: '',
        text: '',
        link: {
          isInternalUrl: false,
          url: '',
          text: 'Ver todos',
        },
        modules: [],
        submodules: [],
        posts: [],
      },
      thirdSection: {
        active: true,
        title: '',
        backgroundImage: 'img/noticias.png',
        images: [],
      },
      fourthSection: {
        active: true,
        title: '',
        text: '',
        button: {
          text: '',
        },
        image: '',
        link: {
          isInternalUrl: false,
          url: '',
        },
      },
      fifthSection: {
        active: true,
        title: '',
        selectedFilters: [],
      },
      sixthSection: {
        active: false,
        title: '',
      },
    },
    heroSection: {
      title: '',
      subtitle: '',
      second_subtitle: '',
    },
    headerSection: {
      login_button: 'Iniciar sesión',
      register_button: 'Registro',
    },
    columnsSection: {
      city: '',
      slogan: '',
      title_column1: '',
      title_column2: '',
      title_column3: '',
      info_column1: '',
      info_column2: '',
      info_column3: '',
    },
    reminderSection: {
      reminderTitle: '',
      reminderText: '',
      text: '',
    },
    certificateSection: {
      certificate_title: '',
    },
    digitalCitizen: {
      title: '',
      subtitle: '',
      levels_description: '',
      step_1: {
        description: '',
      },
      step_2: {
        description: '',
      },
      step_3: {
        description: '',
      },
      step_4: {
        description: '',
      },
      step_5: {
        description: '',
      },
    },
    whatIsCidi: {
      section_1: {
        title: '',
        description: '',
      },
      section_2: {
        title: '',
        description: '',
      },
      section_3: {
        title: '',
        description: '',
      },
      section_4: {
        title: '',
        description: '',
      },
    },
    faq: false,
    faqText: [
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
      {
        title: '',
        description: '',
      },
    ],
    step_number_color: '',
    loaded: false,
  },
})

export const mutations = {
  SET_LANDINGTEXT(state, landingtext) {
    state.landingtext = landingtext
  },
  UPDATE_ELEMENT(state, { element, data, index, subelement }) {
    if (index || index === 0) {
      if (subelement)
        set(state.landingtext, `${element}[${index}].${subelement}`, data)
      else set(state.landingtext, `${element}[${index}]`, data)
    } else set(state.landingtext, element, data)
  },
}

export const actions = {
  async get({ commit, rootState }) {
    await getLastLandingText(this.$fire).then((result) => {
      const landingText = cloneDeep(rootState.landingtext.landingtext)
      if (!result.empty) {
        const data = {
          ...result.docs[0].data(),
          id: result.docs[0].id,
        }
        commit('SET_LANDINGTEXT', merge({ ...landingText }, { ...data }))
      }
    })
    commit('SET_LANDINGTEXT', {
      ...rootState.landingtext.landingtext,
      loaded: true,
    })
  },
}

export const getters = {
  landingtext: (state) => {
    return state.landingtext
  },
}
